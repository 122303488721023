


/*===============================
    27.TEAM DETAILS css 
================================*/


.team-details-area{
	& .team-details-thumb{
		& img{
			width: 100%;
		}
	}
	& .team-details-content{
		padding-left: 50px;
		@media #{$md} {
			margin-top: 30px;
			padding-left: 0;
		}
		@media #{$xs} {
			margin-top: 30px;
			padding-left: 0;
		}
		& .title{
			font-size: 34px;
			padding-bottom: 5px;
			@media #{$xs} {
				font-size: 22px;
			}
			@media #{$sm} {
				font-size: 32px;
			}
		}
		& span{
			font-weight: 700;
			color: $theme-color;
			padding-bottom: 24px;
		}
		& p{
			color: #69a1bb;
		}
		& ul{
			margin-top: 35px;
			& li{
				display: inline-block;
				& a{
					height: 60px;
					width: 60px;
					text-align: center;
					line-height: 60px;
					border-radius: 50%;
					background: #e5f6ff;
					color: #69a1bb;
					margin-right: 8px;
					@include transition(0.3s);
					@media #{$xs} {
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin-right: 7px;
					}
					&:hover{
						background: $theme-color;
						color: $white;
					}
				}
			}
		}
	}
}
