/*-----------------------------------------------------------------------------------

    Template Name: Sample - Omnivus | Technology IT Solutions & Services HTML5 Template
    更多模板下载：http://www.bootstrapmb.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. banner
	05. services
	06. portfolio
	07. faq
	08. team
	09. action
	10. blog
	11. footer

-----------------------------------------------------------------------------------*/
.sticky{
	background-color: white !important;
	.navbar {
		background-color: white !important;
	}
}
@import 'variables';
@import 'mixin';
@import 'common';
@import 'header';
@import 'banner';
@import 'services';
@import 'video';
@import 'portfolio';
@import 'faq';
@import 'team';
@import 'action';
@import 'blog';
@import 'footer';
@import 'about';
@import 'what-we-do';
@import 'intro-video';
@import 'leadership';
@import 'progress-bar';
@import 'quote';
@import 'answers';
@import 'action-2';
@import 'news';
@import 'features';
@import 'choose';
@import 'portfolio-3';
@import 'counter';
@import 'get-in-touch';
@import 'about-experience';
@import 'about-intro';
@import 'about-history';
@import 'services-item';
@import 'services-plans';
@import 'services-optimization';
@import 'case-details';
@import 'team-join';
@import 'team-details';
@import 'shop';
@import 'shop-details';
@import 'blog-grid';
@import 'blog-standard';
@import 'blog-details';
@import 'error';
@import 'contact';
@import 'who-we-are';
@import 'solution';
@import 'latest-services';
@import 'why-choose';
@import 'case-studies';
@import 'team-member';
@import 'contact-us';
@import 'our-choose';
@import 'meet-us';
@import 'latest-news';

@import "custom";


